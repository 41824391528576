.contact h2 {
	font-size: 54px;
}

.contact h3 {
	margin-top: 30px;
	font-size: 36px;
}

@media only screen and (max-width: 600px) {
	.contact h2 {
		font-size: 38px;
	}

	.contact h3 {
		margin-top: 30px;
		font-size: 24px;
	}
}
