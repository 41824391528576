.list-wrapper {
}

.list-wrapper .lists {
	display: grid;
	grid-template-columns: 50% 50%;
	margin-top: 30px;
}

.list-wrapper .lists div {
	margin: 20px 0;
}

ul {
	padding-left: 22px;
}

.list-wrapper .lists div li {
	font-size: 20px;
	margin-bottom: 7px;
}

.list-wrapper-title {
	font-size: 54px;
}

.lists h3 {
	font-size: 36px;
}

@media only screen and (max-width: 600px) {
	.list-wrapper .lists {
		grid-template-columns: 100%;
	}

	.list-wrapper-title {
		font-size: 38px;
	}

	.lists h3 {
		font-size: 24px;
	}
}
